import {
  Box,
  Container,
  Grid,
  TextField,
  Button,
  styled,
} from '@material-ui/core'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { navigate, PageProps } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { decryptData } from 'manage-tritag/utils/crypto'
import axios from 'axios'
import { toast } from 'react-toastify'
import Spinner from '../../../components/loading/spinner'
import PageHeader from '../../../components/page-header'
import MobileNavbar from '../../../components/navbar/mobile'
import Navbar from '../../../components/navbar'
import { useCart } from '../../../hooks/useCart'

const StyledContainer = styled(Container)`
  margin-top: 60px;
`

const StyledPrimaryButton = styled(Button)`
  background-color: #008174;
  font-weight: bold;
  padding: 10px;
  width: 300px;
`
const StyledGrid = styled(Grid)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        textAlign: 'center',
      },
    }
  }}
  text-align: right;
`

const RegisterTeamDetailsPage = (props: PageProps) => {
  const cartType = 'team'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      teamName: '',
      primaryContact: '',
      primaryEmail: '',
      primaryNumber: '',
    },
  })

  const { cart, isCartLoading, setTeamDetails } = useCart({
    type: cartType,
  })

  useEffect(() => {
    if (!isCartLoading && !cart?.data?.competition) {
      navigate(`/register/team/venue`)
    }
  }, [cart?.data?.competition, isCartLoading])

  useEffect(() => {
    if (cart?.data?.teamDetails) {
      reset({
        teamName: cart?.data?.teamDetails?.teamName,
        primaryContact: cart?.data?.teamDetails?.primaryContact,
        primaryEmail: cart?.data?.teamDetails?.primaryEmail,
        primaryNumber: cart?.data?.teamDetails?.primaryNumber,
      })
    }
  }, [cart?.data?.teamDetails])

  const onSubmit: SubmitHandler<any> = async (createData: any) => {
    setIsSubmitting(true)
    const competitionId = cart?.data?.competition?._id
    try {
      const res = await axios.post(
        `https://api.tritagrugby.com/competition/team-name-validator`,
        {
          competitionId,
          name: createData.teamName,
        },
        {
          headers: {
            // this is only a test token
            mahanginako: decryptData(localStorage.getItem('mahiwagangTanzan')),
          },
        },
      )

      if (res) {
        if (res.data) {
          if (res.data.isUsed) {
            setIsSubmitting(false)
            toast.error('Team name already taken in this competition')
          } else {
            setIsSubmitting(false)
            await setTeamDetails({
              teamDetails: createData,
              type: cartType,
            }).unwrap()
            navigate('/register/team/uniform-design')
          }
        }
      }
    } catch (error) {
      setIsSubmitting(false)
      alert('Something went wrong, Please try again.')
    }
  }

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <StyledContainer>
        <Box
          sx={{
            marginTop: '20px',
          }}
        >
          <PageHeader title="Team details" />
        </Box>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="teamName"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="margin-none"
                    label="Team name"
                    variant="filled"
                    size="small"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="primaryContact"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="margin-none"
                    label="Primary contact"
                    variant="filled"
                    size="small"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="primaryEmail"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="margin-none"
                    label="Primary contact email"
                    variant="filled"
                    size="small"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="primaryNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="margin-none"
                    label="Primary contact number"
                    variant="filled"
                    size="small"
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />
            </Grid>
            <StyledGrid item xs={12}>
              <StyledPrimaryButton type="submit" variant="contained">
                {isCartLoading || isSubmitting ? (
                  <Spinner size={22} color="#fff" />
                ) : (
                  <>
                    Next{' '}
                    <ArrowForwardIcon
                      style={{
                        fontSize: '16px',
                        marginLeft: '10px',
                      }}
                    />
                  </>
                )}
              </StyledPrimaryButton>
            </StyledGrid>
          </Grid>
        </Box>
      </StyledContainer>
    </>
  )
}

export default withAuthenticationRequired(RegisterTeamDetailsPage)
